<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <h1>Ficha de Entrevista</h1>
    <div class="p-field p-col-12 p-md-12">
      <form class="p-col-12" @submit.prevent="saveInterview">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-3">
            <span class="p-float-label">
              <InputText
                type="text"
                v-validate="'required'"
                :disabled="
                  entreview.delete ||
                    entreview.archive ||
                    entreview.admission ||
                    entreview.new_interview
                "
                v-model="entreview.responsable"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('responsable')
                  },
                  'form-control'
                ]"
                :name="'responsable'"
              />
              <label for="responsable">Responsável pela Entrevista</label>
            </span>
            <small v-if="errors.has('responsable')" class="p-error" role="alert">
              Responsável pela Entrevista é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <span class="p-float-label">
              <InputText
                type="text"
                v-validate="'required'"
                :disabled="
                  entreview.delete ||
                    entreview.archive ||
                    entreview.admission ||
                    entreview.new_interview
                "
                v-model="entreview.candidate"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('candidate')
                  },
                  'form-control'
                ]"
                :name="'candidate'"
              />
              <label for="candidate">Candidato</label>
            </span>
            <small v-if="errors.has('candidate')" class="p-error" role="alert">
              Candidato é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <span class="p-float-label">
              <InputText
                type="text"
                v-validate="'required|email'"
                v-model="entreview.candidate_email"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('candidate_email')
                  },
                  'form-control'
                ]"
                :name="'candidate_email'"
                :disabled="
                  entreview.delete ||
                    entreview.archive ||
                    entreview.admission ||
                    entreview.new_interview
                "
              />
              <label for="candidate_email">Email</label>
            </span>
            <small
              v-if="errors.has('candidate_email')"
              class="p-error"
              role="alert"
            >
              Email do Candidato é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <span class="p-float-label">
              <InputText
                type="text"
                v-validate="'required'"
                v-model="entreview.candidate_phone"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('candidate_phone')
                  },
                  'form-control'
                ]"
                :name="'candidate_phone'"
                :disabled="
                  entreview.delete ||
                    entreview.archive ||
                    entreview.admission ||
                    entreview.new_interview
                "
              />
              <label for="candidate_phone">Telefone</label>
            </span>
            <small
              v-if="errors.has('candidate_phone')"
              class="p-error"
              role="alert"
            >
              Telefone do Candidato é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <v-date-picker
              v-model="entreview.date"
              mode="datetime"
              is24hr
              is-required
              :masks="{
                input: 'DD-MM-YYYY'
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <span class="p-float-label">
                  <input
                    :disabled="
                      entreview.delete ||
                        entreview.archive ||
                        entreview.admission ||
                        entreview.new_interview
                    "
                    v-bind:class="[
                      {
                        'p-error': errors.has('date')
                      },
                      'form-control',
                      'p-inputtext',
                      'p-component',
                      'p-filled'
                    ]"
                    :value="inputValue"
                    v-on="inputEvents"
                    :name="'date'"
                    v-validate="'required'"
                  />
                  <label for="inputtext">Dia/Hora</label>
                </span>
                <small v-if="errors.has('date')" class="p-error" role="alert">
                  Dia/hora é obrigatório
                </small>
              </template>
            </v-date-picker>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <Textarea
                type="text"
                :autoResize="true"
                rows="5"
                v-model="entreview.qualifications"
                v-bind:class="['form-control']"
                :name="'qualifications'"
                :disabled="
                  entreview.delete ||
                    entreview.archive ||
                    entreview.admission ||
                    entreview.new_interview
                "
              />
              <label>Habilitações/Formação</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <Textarea
                type="text"
                :autoResize="true"
                rows="5"
                v-model="entreview.experience"
                v-bind:class="['form-control']"
                :name="'experience'"
                :disabled="
                  entreview.delete ||
                    entreview.archive ||
                    entreview.admission ||
                    entreview.new_interview
                "
              />
              <label>Experiência Profissional</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <Textarea
                type="text"
                :autoResize="true"
                rows="5"
                v-model="entreview.know_how_to_do"
                v-bind:class="['form-control']"
                :name="'know_how_to_do'"
                :disabled="
                  entreview.delete ||
                    entreview.archive ||
                    entreview.admission ||
                    entreview.new_interview
                "
              />
              <label>Saber fazer</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <Textarea
                type="text"
                :autoResize="true"
                rows="5"
                v-model="entreview.values"
                v-bind:class="['form-control']"
                :name="'values'"
                :disabled="
                  entreview.delete ||
                    entreview.archive ||
                    entreview.admission ||
                    entreview.new_interview
                "
              />
              <label>Valores/Atitudes</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <Textarea
                type="text"
                :autoResize="true"
                rows="5"
                v-model="entreview.more_info"
                v-bind:class="['form-control']"
                :name="'more_info'"
                :disabled="
                  entreview.delete ||
                    entreview.archive ||
                    entreview.admission ||
                    entreview.new_interview
                "
              />
              <label>Resultados de Outros Momentos de Avaliação</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <Textarea
                type="text"
                :autoResize="true"
                rows="5"
                v-model="entreview.logistical_conditions"
                v-bind:class="['form-control']"
                :name="'logistical_conditions'"
                :disabled="
                  entreview.delete ||
                    entreview.archive ||
                    entreview.admission ||
                    entreview.new_interview
                "
              />
              <label>Aspectos Logísticos/Condições</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-3">
            <span class="p-float-label">
              <Textarea
                type="text"
                :autoResize="true"
                rows="5"
                v-model="entreview.aditional_comments"
                v-bind:class="['form-control']"
                :name="'aditional_comments'"
                :disabled="
                  entreview.delete ||
                    entreview.archive ||
                    entreview.admission ||
                    entreview.new_interview
                "
              />
              <label>Opinião Geral/Comentários Adicionais:</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-1 p-mt-3">
            <span
              class="p-float-label"
              style="top: -0.75rem; left: -1rem; font-size: 12px"
            >
              <label for="admission">Admissão</label>
            </span>
            <InputSwitch
              v-model="entreview.admission"
              name="admission"
              style="'top: 3px;"
              :disabled="
                entreview.delete || entreview.archive || entreview.new_interview
              "
            />
          </div>
          <div class="p-field p-col-12 p-md-1 p-mt-3">
            <v-date-picker
              v-model="entreview.admission_date"
              is-required
              :masks="{
                input: 'DD-MM-YYYY'
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <span class="p-float-label">
                  <input
                    :disabled="
                      entreview.delete ||
                        entreview.archive ||
                        entreview.new_interview
                    "
                    v-bind:class="[
                      {
                        'p-error': errors.has('admission_date')
                      },
                      'form-control',
                      'p-inputtext',
                      'p-component',
                      'p-filled'
                    ]"
                    :value="inputValue"
                    v-on="inputEvents"
                    :name="'admission_date'"
                    v-validate="entreview.admission ? 'required' : ''"
                  />
                  <label for="inputtext">Data de Admissão</label>
                </span>
                <small
                  v-if="errors.has('admission_date')"
                  class="p-error"
                  role="alert"
                >
                  Data de Admissão é obrigatório
                </small>
              </template>
            </v-date-picker>
          </div>
          <div class="p-field p-col-12 p-md-1 p-mt-3">
            <span
              class="p-float-label"
              style="top: -0.75rem; left: -1rem; font-size: 12px"
            >
              <label for="new_interview">Nova Entrevista</label>
            </span>
            <InputSwitch
              v-model="entreview.new_interview"
              name="new_interview"
              style="'top: 3px;"
              :disabled="
                entreview.delete || entreview.archive || entreview.admission
              "
            />
          </div>
          <div class="p-field p-col-12 p-md-1 p-mt-3">
            <span
              class="p-float-label"
              style="top: -0.75rem; left: -1rem; font-size: 12px"
            >
              <label for="archive">Arquivar</label>
            </span>
            <InputSwitch
              v-model="entreview.archive"
              name="archive"
              style="'top: 3px;"
              :disabled="
                entreview.delete ||
                  entreview.admission ||
                  entreview.new_interview
              "
            />
          </div>
          <div class="p-field p-col-12 p-md-1 p-mt-3">
            <span
              class="p-float-label"
              style="top: -0.75rem; left: -1rem; font-size: 12px"
            >
              <label for="delete">Eliminar</label>
            </span>
            <InputSwitch
              v-model="entreview.delete"
              name="delete"
              style="'top: 3px;"
              :disabled="
                entreview.archive ||
                  entreview.admission ||
                  entreview.new_interview
              "
            />
          </div>
        </div>
        <Button
          :label="entreview.id == undefined ? 'Criar' : 'Gravar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveInterview"
        />
        <Button
          :label="'Cancelar'"
          icon="pi pi-times"
          class="p-button-danger p-ml-2"
          @click="cancelInterview"
        />
      </form>
    </div>
  </div>
</template>

<script>
import interviewservice from "../services/interviewSheet.service";
import { getOnlyDate, getOnlyTime } from "../helpers/helpers";

export default {
  name: "InterviewSheets",
  data() {
    return {
      loading: true,
      entreview: {},
    };
  },
  async created() {
    if ((this.$route.params.interviewId != undefined && this.$route.params.interviewId > 0)) {
      this.getInterview();
    } else {
      this.entreview = this.generateEmptyObj();
      this.entreview.responsable = this.$store.state.auth.user.username;
    }
  },
  methods: {
    generateEmptyObj() {
      return {
        date: null,
        candidate: null,
        candidate_email: null,
        candidate_phone: null,
        qualifications: null,
        experience: null,
        know_how_to_do: null,
        values: null,
        more_info: null,
        logistical_conditions: null,
        aditional_comments: null,
        admission: null,
        admission_date: null,
        new_interview: null,
        archive: null,
        delete: null,
        responsable: null
      };
    },
    getInterview() {
      if (this.$route.params.interviewId == undefined || this.$route.params.interviewId == 0) {
        return;
      }
      this.loading = true;
      return interviewservice
        .getInterview(this.$route.params.interviewId)
        .then(response => {
          this.loading = false;

          if (response.admission == 1) {
            response.admission = true;
          } else {
            response.admission = false;
          }
          if (response.new_interview == 1) {
            response.new_interview = true;
          } else {
            response.new_interview = false;
          }
          if (response.archive == 1) {
            response.archive = true;
          } else {
            response.archive = false;
          }
          if (response.delete == 1) {
            response.delete = true;
          } else {
            response.delete = false;
          }
          if (response.admission_date != null) {
            response.admission_date = new Date(response.admission_date)
          }
          return (this.entreview = response);
        });
    },
    cancelInterview() {
      this.$router.push(`/interview-sheet`);
    },
    saveInterview() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          return;
        }
        if (this.entreview.id != undefined) {
          return this.updatInterview();
        }
        return this.saveNew();
      });
    },
    updatInterview() {
      let modelKeys = this.generateEmptyObj();
      let bodyParms = {};
      Object.keys(modelKeys).forEach(v => {
        if (this.entreview[v] != null) {
          bodyParms[v] = this.entreview[v];
        }
      });

      bodyParms.date = `${getOnlyDate(this.entreview.date)} ${getOnlyTime(
        this.entreview.date
      )}`;

      if (bodyParms.admission_date != undefined && bodyParms.admission_date instanceof Date) {
        bodyParms.admission_date = getOnlyDate(this.entreview.admission_date);
      }

      return interviewservice
        .updateInterview(this.entreview.id, bodyParms)
        .then(response => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao gravar",
              life: 3000
            });
          }

          return this.$toast.add({
            severity: "success",
            summary: "Ficha de Entrevista Alterada",
            detail: "A ficha de entrevista foi alterado com sucesso",
            life: 3000
          });
        });
    },
    saveNew() {
      let modelKeys = this.generateEmptyObj();
      let bodyParms = {};
      Object.keys(modelKeys).forEach(v => {
        if (this.entreview[v] != null) {
          bodyParms[v] = this.entreview[v];
        }
      });

      bodyParms.date = `${getOnlyDate(this.entreview.date)} ${getOnlyTime(
        this.entreview.date
      )}`;

      if (bodyParms.admission_date != undefined) {
        bodyParms.admission_date = getOnlyDate(new Date(this.entreview.admission));
      }

      return interviewservice.createInterview(bodyParms).then(response => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao gravar",
            life: 3000
          });
        }

        this.$toast.add({
          severity: "success",
          summary: "Ficha de Entrevista Alterada",
          detail: "A ficha de entrevista foi alterado com sucesso",
          life: 3000
        });

        return this.$router.push(`/interview-sheet`);
      });
    }
  }
};
</script>
